import request from '@/service/request'

/**
 * 角色接口
 */
// 获取角色列表 page接口
export const getRoleList = (params) => {
  return request.post('/sys/role/page', params)
}

// 获取角色列表  list接口
export const listRoleApi = () => {
  return request.post('/sys//role/list')
}

// 添加角色
export const addRole = (params) => {
  return request.post('/sys/role/save', params)
}

// 根据id获取角色信息
export const getRoleInfoById = (params) => {
  return request.post('/sys/role/getById/' + params)
}

// 根据id删除角色
export const deleteRoleById = (params) => {
  return request.post('/sys/role/del/' + params)
}

// 根据id获取角色菜单
export const getRoleMenuById = (params) => {
  return request.post('/sys/role/listMenuIdByRoleId/' + params)
}

// 分配角色菜单
export const setRoleMenu = (params) => {
  return request.post('/sys/role/saveRoleMenu', params)
}
