<template>
  <el-dialog
    class="set-role-dialog"
    :title="`分配角色${title}`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="680px"
  >
    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item label="角色">
        <tool-tips-group is-flex
                         tips-content="<b>同一账号无法同时分配多个请假审批角色。</b><br/>请假审批角色包括：辅导员、学生处、副院长、校领导等，<br/>更多详情请到”系统设置-角色管理“模块查看或编辑。">
          <el-select ref="roleSelectRef" v-model="formData.roleIdList" multiple filterable placeholder="请选择角色">
            <el-option
              v-for="{roleName,id} in roleList"
              :label="roleName"
              :value="id"
              :key="id" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { listRoleApi } from '@/api/system/role-api'
import { getRoleListById, setUserRole } from '@/api/system/user-api'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  data () {
    return {
      formData: {
        userId: '',
        roleIdList: []
      },
      roleList: [] // 全部角色列表
    }
  },
  mounted () {
    this.getRoleList()
    this.$nextTick(() => {
      if (this.id) {
        this.getRoleListById(this.id)
      }
    })
  },
  methods: {
    // 根据用户id获取角色列表
    getRoleListById (id) {
      getRoleListById(id).then((res) => {
        this.formData.roleIdList = res.data
        this.formData.userId = id
        this.setInitData()
      })
    },
    // 获取全部角色列表
    getRoleList () {
      listRoleApi().then((res) => {
        this.roleList = res.data
      })
    },
    async handleSave () {
      if (this.$refs.roleSelectRef.selected.length) {
        const _selectRoleList = this.$refs.roleSelectRef.selected.filter(v => v.label.includes('请假审批角色'))
        if (_selectRoleList.length > 1) {
          this.$message.error(`以下【请假审批角色】不可分配给同一个账号：${_selectRoleList.map(({ label }) => label)}!`)
          return
        }
      }
      await this.save('角色分配', setUserRole, '分配成功!')
    }
  }
}
</script>
<style lang="scss">
.set-role-dialog {
  .el-dialog {
    margin-top: 36vh !important;
  }
}
</style>
