<!-- /user 系统管理-用户管理  /hrManage 人事处-人事管理 -->
<template>
  <table-view :total="total" :query-info.sync="queryInfo" v-loading="loading" @reload-table="renderTable">
    <template #header>
      <view-search-form ref="searchFormRef" keyword-placeholder="姓名" :queryInfo.sync="queryInfo"
                        :tool-list="['department', 'college', 'keyword']" :insert-select-all="['department', 'college']"
                        :filter-keys="['userType']" @on-search="renderTable(1)" label-width="55px">
        <el-form-item v-if="isRoleEditor" label="角色">
          <el-select v-model="queryInfo.roleId" filterable size="small" @change="queryInfo.roleIds = [$event]">
            <el-option v-for="{ id, roleName } in roleList" :key="id" :label="roleName" :value="id" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <upload-excel v-if="isRoleEditor" :loading.sync="loading" :api="$http.importExcelHttp.importUser"
                      @on-download="$http.exportExcelHttp.exportUserTemplate()" @on-success="renderTable(1)" />
        <upload-excel v-else :loading.sync="loading" :api="$http.importExcelHttp.importUserByHR"
                      @on-download="$http.exportExcelHttp.exportUserByHRTemplate()" @on-success="renderTable(1)" />
        <el-button size="small" type="success" @click="exportUser()">批量导出用户</el-button>
        <!--    后端说人事处不支持导出带照片的用户  -->
        <el-button v-if="isRoleEditor" type="success" plain
                   size="small" piain
                   @click="visibleExportDialog = true">批量导出(带照片)
        </el-button>
        <el-button size="small" type="danger" @click="deleteUsers" v-permission="['sys:user:delete','hr:delete']"
                   :disabled="!rowCheckList.length">批量删除用户
        </el-button>
        <el-button v-permission="['sys:user:edit','hr:edit']" size="small" type="primary" @click="showDialog()">添加用户
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="40" :selectable="handleRowSelectable" />
      <el-table-column label="姓名" width="150">
        <template v-slot="{ row }">
          {{ row.realName }}{{ row.canFlag ? '(已注销)' : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="deptName" label="部门/院系" width="150">
        <template v-slot="{ row }">
          {{ row.deptName ? row.deptName : (row.collegeName ? row.collegeName : '') }}
        </template>
      </el-table-column>
      <el-table-column prop="username" label="工号" width="150" />
      <el-table-column prop="cardNum" label="身份证号" width="170" />
      <el-table-column prop="phone" label="手机号码" width="150" />
      <el-table-column prop="teacherType" label="职工类别" />
      <el-table-column prop="entryTime" label="入职时间" width="100" />
      <template v-if="isRoleEditor">
        <el-table-column prop="roles" label="用户角色" min-width="260">
          <template v-slot="{ row }">
            <el-tag class="mb-1" style="margin-left: 10px" type="info" v-for="(item, index) in row.roleNameList"
                    :key="index">{{ item }}
            </el-tag>
          </template>
        </el-table-column>
      </template>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="300" fixed="right">
        <template v-slot="{ row }">
          <!--  人事处模块 无权限处理  管理员和开发人员的 数据     -->
          <template
            v-if="!intersection(['开发人员', '超级管理员'], row.roleNameList).length||$store.state.activeRouter.activeRouteInfo.path === '/user'">
            <el-button type="primary" plain size="mini" v-if="isRoleEditor"
                       @click="showSubDialog('visibleRoleDialog', 'roleDialogRef', row.id, `-${row.realName}`)">分配角色
            </el-button>
            <el-button type="primary" size="mini" v-permission="['sys:user:edit','hr:edit']"
                       @click="showDialog(row.id, `:${row.realName}(${row.username})`)">编辑
            </el-button>
            <el-button type="primary" size="mini" v-permission="['sys:user:edit','hr:edit']" @click="resetPassword(row)">
              初始化密码
            </el-button>
            <el-button size="mini" type="danger" v-permission="['sys:user:delete','hr:delete']"
                       @click="del(deleteUserById, row.id, `账号-${row.realName}(${row.username})`)">删除
            </el-button>
          </template>
          <div v-else class="font-grey font-center">--管理员账号，无法操作--</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  添加/编辑  -->
    <user-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" :is-role-editor="isRoleEditor"
                 @on-close="renderTable" />
    <set-role ref="roleDialogRef" :visible.sync="visibleRoleDialog" v-if="visibleRoleDialog && isRoleEditor"
              @on-close="renderTable" />
    <export-user-dialog :visible.sync="visibleExportDialog" v-if="visibleExportDialog && isRoleEditor"
                        :is-role-editor="isRoleEditor" />
  </table-view>
</template>

<script>
import { getUserList, deleteUserById, resetPasswordApi, batchDeleteUsersApi } from '@/api/system/user-api'
import userDialog from './component/userDialog.vue'
import setRole from './component/setRole.vue'
import tableView from '@/vue/mixins/table-view'
import { listRoleApi } from '@/api/system/role-api'
import ExportUserDialog from '@/views/pages/system/userList/component/exportUserDialog.vue'
import { intersection } from 'lodash'

export default {
  name: 'userList',
  components: {
    ExportUserDialog,
    userDialog,
    setRole
  },
  mixins: [tableView],
  data() {
    return {
      visibleRoleDialog: false,
      visibleExportDialog: false,
      isRoleEditor: false,
      queryInfo: {
        userType: 3, // 1-教师，2-学生，3-管理员，默认为管理员，不可改
        collegeId: '',
        deptId: '',
        roleId: '',
        roleIds: []
      },
      roleList: [],
      rowCheckList: []
    }
  },
  mounted() {
    this.getRoleList()
    this.getRoleEditor()
  },
  methods: {
    intersection, // lodash 用于找出数组交集
    deleteUserById, // 删除api
    // 用户多选
    handleSelectionChange(list) {
      this.rowCheckList = []
      list.map((item) => {
        this.rowCheckList.push(item.id)
      })
    },
    // 设置禁选
    handleRowSelectable(row) {
      if (this.isRoleEditor) {
        return true
      } else {
        return !intersection(['开发人员', '超级管理员'], row.roleNameList).length
      }
    },
    // 批量删除
    async deleteUsers() {
      const data = {
        ids: this.rowCheckList
      }
      this.loading = true
      this.$confirm(`<p class="confirm-message">是否确定删除选中的 <b class="danger">${data.ids.length}个用户</b> 的账号？</p>`, '批量删除用户', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        try {
          await batchDeleteUsersApi(data)
          this.$message.success(`已成功删除这${data.ids.length}个用户的账号！`)
          await this.renderTable()
        } catch (e) {
          if (!e) {
            this.$message.error('删除失败，请重试~')
          }
        } finally {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 批量导出
    exportUser() {
      const data = {
        userType: 3,
        collegeId: this.queryInfo.collegeId,
        keyword: this.queryInfo.keyword,
        deptId: this.queryInfo.deptId,
        roleId: this.queryInfo.roleId,
        roleIds: this.queryInfo.roleIds
      }
      if (this.isRoleEditor) {
        // 系统设置
        this.$http.exportExcelHttp.exportUser(data)
      } else {
        data.roleId = null
        data.roleIds = []
        // 人事处
        this.$http.exportExcelHttp.exportUserByHR(data)
      }
    },
    // 获取用户列表
    async renderTable(pageNum) {
      await this.getTableData(getUserList, pageNum)
      if (this.tableData.length) {
        this.tableData.forEach(({
                                  roleNameList,
                                  showSelectClassesFlag
                                }) => {
          if (roleNameList?.length && roleNameList.includes('请假审批角色：辅导员')) {
            // showSelectClassesFlag = true
          }
        })
      }
    },
    // 获取角色list
    async getRoleList() {
      try {
        const res = await listRoleApi()
        this.roleList = res.data
      } catch (e) {
      }
    },
    getRoleEditor() {
      // '/hrManage' 为人事管理，不能分配角色
      this.isRoleEditor = this.$store.state.login.userInfo.paraNameList.includes('sys:user:role') && (this.$store.state.activeRouter.activeRouteInfo.path === '/user')
    },
    // 初始化密码
    resetPassword(row) {
      this.$confirm(`是否确定初始化 <b style="color:var(--color-primary)">${row.realName}(${row.username})</b> 的密码？`, '初始化密码', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        try {
          await resetPasswordApi(row.id)
          this.$message.success(`账号${row.realName}(${row.username})的密码已成功初始化为 nfxy14265 ,为保障账号安全,请尽快修改密码!`)
        } catch (e) {
        }
      })
    }
  }
}
</script>
