// 心理咨询室 教师
import request from '@/service/request'

/* 心理咨询室，分页
* @params
* */
export const pagePsychologyTeacherApi = (params) => {
  return request.post('/stu/psychologyTeacherInfo/page', params)
}

/* 心理咨询室，listAll
* @params
* */
export const listPsychologyTeacherApi = (params = {}) => {
  return request.post('/stu/psychologyTeacherInfo/list', params)
}

/* 心理咨询室，获取教师详情
* @params {string} id
*/
export const getPsychologyTeacherByIdApi = (id) => {
  return request.post(`/stu/psychologyTeacherInfo/get/${id}`)
}

/* 心理咨询室， 删除教师
* @params {string} id
* */
export const delPsychologyTeacherByIdApi = (id) => {
  return request.post(`/stu/psychologyTeacherInfo/del/${id}`)
}

/* 心理咨询室，新增逻辑：
* 1。先申请提交到'/sys/user/save'
* 2.获取返回的id后 作为userId
* 3.请求 '/stu/psychologyTeacherInfo/save' 保存 */

/* 心理咨询室，编辑后保存（不能新增）
* @params
* */
export const savePsychologyTeacherApi = (params) => {
  return request.post('/stu/psychologyTeacherInfo/save', params)
}

/* 心理教师，编辑逻辑：
* 1。存在id才能编辑
* 2.编辑switch立即执行该法
* @params {number} psychologyFlag 0-非 1-是
* @params {string} userId 教师id
*
 */
export const editPsychologyTeacherApi = (params) => {
  return request.post('/sys/user/editPsychology', params)
}
